<template>
  <div>
    <div class="title">
      使用手册
    </div>
    <div class="container">
      <div class="show_list flex align_center">
        <div class="left_video">
          <video 
            class="video"
            muted
            controls 
            autoplay
            playsinline
            webkit-playsinline
            x5-playsinline
            x5-video-player-type="h5"
            x5-video-player-fullscreen="true"
            x5-video-orientation="portraint"
          >
          <source type="video/mp4" src="http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/c2a8d139387702298222085919/TpCMpLkpDB0A.mp4">
          </video>
        </div>
        <div class="right_main">
          <div>羽林二类电商云</div>
          <div>基本使用介绍</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.title {
  font-size: 36px;
  font-family: GraublauWeb;
  font-weight: 400;
  color: #333333;
  margin-bottom: 80px;
  padding-top: 100px;
  text-align: center;
}

.show_list {
  margin-bottom: 150px;
  .left_video {
    width: 600px;
    height: 380px;
    /* background: #163BFA; */
    margin-right: 74px;
    video {
      width: 100%;
      height: 100%;
      &::--webkit-media-controls-play-button{
        display: none !important;
        -webkit-appearance: none !important;
      }
    }
  }
  .right_main {
    flex: 1;
  }
  .right_main > div:nth-child(1) {
    font-size: 30px;
    font-family: GraublauWeb;
    font-weight: 400;
    color: #333333;
    margin-bottom: 30px;
  }

  .right_main > div:nth-child(2) {
    font-size: 24px;
    font-family: GraublauWeb;
    font-weight: 400;
    color: #333333;
  }
}

@media all and (max-width: 1080px) {
  .container {
    .show_list {
      display: flex !important;
      .left_video {
        width: 100%;
        margin-right: 0;
      }

      .right_main {
        padding: 0 10px;
        text-align: center;
        margin-bottom: 20px;
      }
    }
    .flex {
      flex-direction: column-reverse;
    }
  }
}
</style>